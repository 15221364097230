import React, { useEffect, useState } from 'react';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import { graphql } from 'gatsby';
import * as styles from './sitemap.module.scss';
import Banner from '../components/atoms/Banner/Banner';
import Seo from '../components/organisms/Seo/Seo';
import { isMadPawsStore } from '../common/util';

const SitemapPage = ({ data }) => {
  const allPathUrls = data.allSitePage?.edges;
  const [urlsData, setUrlsData] = useState([]);
  const [landingsPage, setLandingsPage] = useState([]);

  useEffect(() => {
    const dataUrls = [];
    const landingPage = [];

    allPathUrls.map(url => {
      const urlPath = url.node.path;
      if (urlPath !== '/') {
        const urlSplitPath = urlPath.split('/').filter(e => e !== '');
        const pageType = urlSplitPath[0];
        const pathName = urlSplitPath[urlSplitPath.length - 1];
        const titlePage = pathName.replaceAll('-', ' ').replace('.html', '');
        const hideUrls = ['404', '404.html', 'pet-medication'];
        if (hideUrls.includes(pathName)) return;
        if (urlSplitPath.length > 1) {
          const pageTypeExisted = dataUrls.find(i => i.pageType === pageType);
          if (!pageTypeExisted) {
            dataUrls.push({
              pageType,
              info: []
            });
          } else {
            pageTypeExisted.info.push({
              title: titlePage,
              path: urlPath
            });
          }
        } else {
          landingPage.push({
            title: titlePage,
            path: urlPath
          });
        }
      }
    });
    const landingPageSorted = landingPage.sort((a, b) => {
      let fa = a.title;
      let fb = b.title;
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    const dataUrlsSorted = dataUrls.sort((a, b) => {
      let fa = a.pageType;
      let fb = b.pageType;
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setUrlsData(dataUrlsSorted);
    setLandingsPage(landingPageSorted);
  }, []);

  if (!data.allSitePage?.edges || data.allSitePage?.edges.length === 0) return;

  return (
    <Layout className={styles.root}>
      <Seo
        title={`Sitemap - ${isMadPawsStore() ? 'Mad Paws Pet Store' : 'Pet Chemist Online'}`}
        description={`Sitemap - ${isMadPawsStore() ? 'Mad Paws Pet Store' : 'Pet Chemist Online'}`}
      ></Seo>
      <div className="banner">
        <Banner maxWidth={'650px'} name={`Sitemap`} height={'350px'} bgColor={`#f4f9fe`} />
      </div>
      <Container size="medium">
        <>
          <h1>Pages</h1>
          <a href="/" target="__blank">
            Pet Medication
          </a>
          <br></br>
          {landingsPage.length > 0 &&
            landingsPage.map((page, index) => (
              <div key={'landing-' + index}>
                <a href={page.path} target="__blank">
                  {page.title}
                </a>
                <br></br>
              </div>
            ))}
          {urlsData.length > 0 &&
            urlsData.map((pageData, i) => (
              <div key={'pages-' + i}>
                <h1>{pageData.pageType}</h1>
                {pageData.info.map((p, id) => (
                  <div key={'page-'+id}>
                    <a href={p.path} target="__blank">
                      {p.title}
                    </a>
                    <br></br>
                  </div>
                ))}
              </div>
            ))}
        </>
      </Container>
    </Layout>
  );
};

export default SitemapPage;

export const query = graphql`
  query {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
